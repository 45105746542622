import React, { Fragment } from "react";
import { Grid, CssBaseline, ThemeProvider } from "@material-ui/core";
import CustomLightTheme from "../../themes/CustomLightTheme";
import Description from "./sub-components/Description";
import Photo from "../../images/DucLakeTahoe.JPG";
import Grow from "@material-ui/core/Grow";
import * as Style from "./AboutStyles";

function About(props) {
  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Grow in={true}>
          <Style.MyGrid
            container
            spacing={4}
            alignItems="center"
            justify="center"
          >
            <Grid key="mypicture" item xs={12} sm={12} md={12} lg={5}>
              <Style.MyAvatarBox>
                <Style.MyAvatar alt="Duc Tran" src={Photo} />
              </Style.MyAvatarBox>
            </Grid>
            <Grid key="description" item xs={12} sm={12} md={12} lg={7}>
              <Description />
            </Grid>
          </Style.MyGrid>
        </Grow>
      </ThemeProvider>
    </Fragment>
  );
}

export default About;
