import React, { Fragment } from "react";
import { CssBaseline } from "@material-ui/core";
import * as Style from "./ProjectSummaryStyles";

export default function ProjectSummary(props) {
  return (
    <Fragment>
      <CssBaseline />
      <Style.MyProjectSummary>{props.data}</Style.MyProjectSummary>
    </Fragment>
  );
}
