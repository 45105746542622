import React, { Fragment } from "react";
import { Grid, CssBaseline, ThemeProvider } from "@material-ui/core";
import { LinkedIn, GitHub } from "@material-ui/icons";
import CustomLightTheme from "../../themes/CustomLightTheme";
import * as Style from "./ConnectWithMeStyles";

const buttonData = [
  {
    key: "linkedin",
    title: "LinkedIn",
    icon: LinkedIn,
    color: CustomLightTheme.palette.icon.blue,
    path: "https://www.linkedin.com/in/duc-v-tran/",
  },
  {
    key: "github",
    title: "Github",
    icon: GitHub,
    color: CustomLightTheme.palette.common.black,
    path: "https://github.com/duketran1996",
  },
  {
    key: "gitlab",
    title: "Gitlab",
    icon: Style.MyGitLabIcon,
    color: "none",
    path: "https://gitlab.com/duketran1996",
  },
];

export default function ConnectWithMe() {
  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Grid container spacing={1} alignItems="center" justify="center">
          {buttonData.map((button) => (
            <Grid key={button.key} item>
              <Style.MyTooltip title={button.title} placement="top">
                <Style.MyIconButton href={button.path}>
                  <Style.MyIcon
                    style={{ color: button.color }}
                    component={button.icon}
                  ></Style.MyIcon>
                </Style.MyIconButton>
              </Style.MyTooltip>
            </Grid>
          ))}
        </Grid>
        <Style.MyCopyRight align="center">
          &copy; Copyright 2020 DT, All Rights Reserved
        </Style.MyCopyRight>
      </ThemeProvider>
    </Fragment>
  );
}
