import React, { Fragment } from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CustomLightTheme from "../../themes/CustomLightTheme";
import * as Style from "./TopBarStyles";

export default function TopBar() {
  const path = ["/", "/about", "/contact"];

  const history = useHistory();

  const handleCallToRouter = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Style.MyAppBar elevation={0} position="relative" color="transparent">
          <Style.MyTabs
            centered
            value={
              path.includes(history.location.pathname)
                ? history.location.pathname
                : false
            }
            onChange={handleCallToRouter}
          >
            <Style.MyTab label="Projects" value={path[0]} />
            <Style.MyTab label="About" value={path[1]} />
            <Style.MyTab label="Contact" value={path[2]} />
          </Style.MyTabs>
        </Style.MyAppBar>
      </ThemeProvider>
    </Fragment>
  );
}
