import React, { Fragment } from "react";
import { Grid, CssBaseline, ThemeProvider } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import CustomLightTheme from "../../themes/CustomLightTheme";
import Grow from "@material-ui/core/Grow";
import * as Data from "../../contents/ContactsContent";
import * as Style from "./ContactStyles";

function Contact(props) {
  const textfieldData = Data.TextFieldContent;

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Grow in={true}>
          <form noValidate autoComplete="off">
            <Grid
              container
              spacing={isWidthDown("sm", props.width) ? 2 : 3}
              direction="column"
              alignItems="center"
              justify="center"
            >
              {textfieldData.map((textfield) => (
                <Style.MyGrid item key={textfield.key}>
                  <Style.MyTextField
                    fullWidth
                    required
                    variant="outlined"
                    size={isWidthDown("sm", props.width) ? "small" : "medium"}
                    multiline={textfield.multiline}
                    rows={isWidthDown("lg", props.width) ? 5 : textfield.rows}
                    label={textfield.label}
                    type={textfield.type}
                  />
                </Style.MyGrid>
              ))}
              <Style.MyGrid item key="submit">
                <Style.MyButton fullWidth>Submit</Style.MyButton>
              </Style.MyGrid>
            </Grid>
          </form>
        </Grow>
      </ThemeProvider>
    </Fragment>
  );
}

export default withWidth()(Contact);
