import React, { Fragment } from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import CustomLightTheme from "../../themes/CustomLightTheme";
import * as Style from "./TitleStyles";

export default function Title() {
  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Style.MyTitle align='center'>Website Developer</Style.MyTitle>
      </ThemeProvider>
    </Fragment>
  );
}