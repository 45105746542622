import React, { Fragment, useState } from "react";
import { Grid, CssBaseline } from "@material-ui/core";
import * as Style from "./ProjectDemoStyles";

export default function ProjectDemo(props) {
  const [open, setOpen] = useState(false);
  const [activeModalImg, setActiveModalImg] = useState(null);

  const handleOpen = (e, img) => {
    setOpen(true);
    setActiveModalImg(img);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <CssBaseline />
      <Style.MyGridContainer
        container
        spacing={4}
        direction="row"
        //direction={isWidthDown("sm", props.width) ? "column" : "row"}
        alignItems="flex-start"
        alignContent="center"
        justify="flex-start"
      >
        {props.data.map((image, index) => (
          <Grid key={image["key"]} item>
            <Style.MyCard>
              <Style.MyCardActionArea
                onClick={(e) => handleOpen(e, image["image"])}
              >
                <Style.MyCardMedia image={image["image"]}></Style.MyCardMedia>
              </Style.MyCardActionArea>
            </Style.MyCard>
          </Grid>
        ))}
        <Style.MyModal open={open} onClose={handleClose}>
          <Style.MyCardModal>
            <Style.MyCardMediaModal
              src={activeModalImg}
            ></Style.MyCardMediaModal>
          </Style.MyCardModal>
        </Style.MyModal>
      </Style.MyGridContainer>
    </Fragment>
  );
}
