import React, { Fragment } from "react";
import { CssBaseline } from "@material-ui/core";
import * as Style from "./ProjectSectionDescriptionStyles";

export default function ProjectSectionDescription(props) {
  return (
    <Fragment>
      <CssBaseline />
      <Style.MyProjectSectionDescription>{props.data}</Style.MyProjectSectionDescription>
    </Fragment>
  );
}