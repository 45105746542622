import { Grid, Divider } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [theme.breakpoints.only("sm")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  [theme.breakpoints.only("md")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  [theme.breakpoints.only("lg")]: {
    paddingLeft: theme.spacing(25),
    paddingRight: theme.spacing(25),
  },
  [theme.breakpoints.only("xl")]: {
    paddingLeft: theme.spacing(50),
    paddingRight: theme.spacing(50),
  },
  paddingBottom: theme.spacing(2),
}));

const MyDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: "100%",
}));

export { MyGridContainer, MyDivider };
