import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import WebFont from "webfontloader";

WebFont.load({
  typekit: {
    id: "adamina;advent-pro;brandon-grotesque",
    api: "//use.edgefonts.net",
  },
  google: {
    families: [
      "Lato:300,400,500,700",
      "Open Sans",
      "Quantico:300,400,500,700",
      "Montserrat:300,400,500,700",
      "Permanent Marker",
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
