import { Grid, TextField, Button } from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";

const TextFieldCustom = withStyles((theme) => ({
  root: {
    "&label": {
      background: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSizeCustom.body1,
      letterSpacing: theme.typography.letterSpacing.body1,
    },
    "&label.Mui-focused": {
      color: theme.palette.primary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSizeCustom.h6,
      letterSpacing: theme.typography.letterSpacing.h6,
    },
  },
}))(TextField);

const MyTextField = styled(TextFieldCustom)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const MyGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    width: "300px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "500px",
  },
}));

const ButtonCustom = withStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(Button);

const MyButton = styled(ButtonCustom)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSizeCustom.body2,
  letterSpacing: theme.typography.letterSpacing.body2,
  lineHeight: theme.typography.lineHeight.body2,
  borderRadius: theme.shape.borderRadius,
}));

export { MyTextField, MyGrid, MyButton };
