import { Grid, Avatar, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  [theme.breakpoints.up("lg")]: {
    paddingRight: 100,
    paddingLeft: 100,
  },
}));

const MyAvatarBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  [theme.breakpoints.up("lg")]: {
    float: "right",
  },
}));

const MyAvatar = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  [theme.breakpoints.only("sm")]: {
    width: theme.spacing(40),
    height: theme.spacing(40),
  },
  [theme.breakpoints.only("md")]: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  [theme.breakpoints.only("lg")]: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  [theme.breakpoints.only("xl")]: {
    width: theme.spacing(60),
    height: theme.spacing(60),
  },
}));

export { MyGrid, MyAvatarBox, MyAvatar };
