const TextFieldContent = [
  {
    key: "name",
    label: "Name",
    type: "text",
    mutiline: false,
    rows: 1,
  },
  {
    key: "email",
    label: "Email",
    type: "text",
    mutiline: false,
    rows: 1,
  },
  {
    key: "phone",
    label: "Phone",
    type: "text",
    mutiline: false,
    rows: 1,
  },
  {
    key: "message",
    label: "Message",
    type: "text",
    multiline: true,
    rows: 10,
  },
];

export { TextFieldContent };
