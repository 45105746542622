import React, { Fragment } from "react";
import { Link, CssBaseline } from "@material-ui/core";
import * as Style from "./ProjectSectionLinkStyles";

export default function ProjectSectionLink(props) {
  return (
    <Fragment>
      <CssBaseline />
      <Style.MyProjectSectionLink>
        <Link href={props.data === "Comming Soon" ? "#" : props.data}>
          {props.data}
        </Link>
      </Style.MyProjectSectionLink>
    </Fragment>
  );
}
