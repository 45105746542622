import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyProjectSummary = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.fontSizeCustom.body2,
    letterSpacing: theme.typography.letterSpacing.body2,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.fontSizeCustom.body1,
    letterSpacing: theme.typography.letterSpacing.body1,
  },
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.dark,
}));

export { MyProjectSummary };
