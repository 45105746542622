import React from "react";
import { Route, withRouter } from "react-router-dom";
import { isValidElementType } from "react-is";

function TransitionRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
}

TransitionRoute.propTypes = {
  component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error(
        "Invalid prop 'component' supplied to 'Route': the prop is not a valid React component"
      );
    }
  },
};

export default withRouter(TransitionRoute);
