import React, { Fragment } from "react";
import { CssBaseline } from "@material-ui/core";
import * as Style from "./DescriptionStyles";

export default function Description() {
  return (
    <Fragment>
      <CssBaseline />
      <Style.MyDescription>
        Enthusiastic computer science student with experience in full stack web
        application development, UX design and machine learning. Proven ability
        to work independently and as part of a team, ensuring that projects are
        completed on time and with minimal supervision. Self-motivated,
        outgoing, and engaged in corporate collaborations. Passionate about
        learning new technology and automation for industrial process.
      </Style.MyDescription>
    </Fragment>
  );
}
