import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.fontSizeCustom.h3,
    letterSpacing: theme.typography.letterSpacing.h3,
  },
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: theme.typography.fontSizeCustom.h3,
    letterSpacing: theme.typography.letterSpacing.h3,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: theme.typography.fontSizeCustom.h2,
    letterSpacing: theme.typography.letterSpacing.h2,
  },
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamilyName,
  color: theme.palette.primary.dark,
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.primary.light,
  },
}));

export { MyName };
