import React from "react";
import { Switch } from "react-router-dom";
import MainLayoutGrid from "../components/LayoutGrid/MainLayoutGrid";
import Project from "../components/ProjectsGrid/Project";
import About from "../components/AboutGrid/About";
import Contact from "../components/ContactGrid/Contact";
import ProjectDetails from "../components/ProjectDetailsGrid/ProjectDetails";
import TransitionRoute from "../components/Routes/TransitionRoute";

export default function MainScene() {
  return (
    <MainLayoutGrid>
      <Switch>
        <TransitionRoute exact path="/" component={Project} />
        <TransitionRoute path="/about" component={About} />
        <TransitionRoute path="/contact" component={Contact} />
        <TransitionRoute path="/:project" component={ProjectDetails} />
      </Switch>
    </MainLayoutGrid>
  );
}
