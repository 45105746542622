import { Card, Grid } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
const MyCard = styled(Card)(({ theme }) => ({}));

const MyGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    width: theme.spacing(40),
    overflowX: "scroll",
    flexWrap: "nowrap",
    alignItems: "left",
    justifyContent: "left",
  },
}));

const MyKeyboardArrowRight = styled(KeyboardArrowRight)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    marginTop: theme.spacing(3),
    width: theme.spacing(6),
    height: theme.spacing(6),
    float: "right",
  },
}));

export { MyGridContainer, MyCard, MyKeyboardArrowRight };
