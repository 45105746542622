import React, { Fragment } from "react";
import { Grid, CssBaseline, ThemeProvider } from "@material-ui/core";
import CustomLightTheme from "../../themes/CustomLightTheme";
import ProjectTitle from "./sub-components/ProjectTitle";
import ProjectSummary from "./sub-components/ProjectSummary";
import ProjectSection from "./sub-components/ProjectSection";
import ProjectSectionDescription from "./sub-components/ProjectSectionDescription";
import ProjectSectionLink from "./sub-components/ProjectSectionLink";
import ProjectDemo from "./sub-components/ProjectDemo";
import Grow from "@material-ui/core/Grow";
import * as Data from "../../contents/ProjectDetailsContent";
import * as Style from "./ProjectDetailsStyles";

export default function ProjectDetails(props) {
  const projects = Data.ProjectDetailsContent;
  const key = props.match.params["project"];

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Grow in={true}>
          <Style.MyGridContainer
            container
            spacing={2}
            direction="column"
            alignItems="flex-start"
            wrap="nowrap"
          >
            <Grid item>
              <ProjectTitle data={projects[key]["title"]} />
            </Grid>
            <Style.MyDivider variant="fullWidth" />
            <Grid item>
              <ProjectSummary data={projects[key]["summary"]} />
            </Grid>
            <Grid item>
              <ProjectSection data={"Built With"} />
            </Grid>
            <Grid item>
              <ProjectSectionDescription data={projects[key]["tech"]} />
            </Grid>
            <Grid item>
              <ProjectSection data={"Link"} />
            </Grid>
            <Grid item>
              <ProjectSectionLink data={projects[key]["link"]} />
            </Grid>
            <Grid item>
              <ProjectSection data={"Demo"} />
            </Grid>
            <Grid item>
              <ProjectDemo data={projects[key]["img"]} />
            </Grid>
          </Style.MyGridContainer>
        </Grow>
      </ThemeProvider>
    </Fragment>
  );
}
