import { Tooltip, IconButton, Icon, Typography } from "@material-ui/core";
import { ReactComponent as GitLab } from "../../icons/gitlab.svg";
import { styled, withStyles } from "@material-ui/core/styles";

const MyTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.fontSizeCustom.body2,
    background: theme.palette.secondary.main,
  },
}))(Tooltip);

const MyGitLabIcon = GitLab;

const MyIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    background: theme.palette.secondary.verylight,
  },
}));

const MyIcon = withStyles((theme) => ({
  root: {
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.fontSizeCustom.iconsm,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.fontSizeCustom.iconlg,
    },
  },
}))(Icon);

const MyCopyRight = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusRound,
  fontSize: theme.typography.fontSizeCustom.caption,
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: theme.typography.letterSpacing.caption,
  color: theme.palette.secondary.verylight,
}));

export { MyTooltip, MyIconButton, MyIcon, MyGitLabIcon, MyCopyRight };
