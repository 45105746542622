import Enigma from "../images/enigma.jpeg";
import Coalition from "../images/coalition.png";
import TruckBux from "../images/truckbux.png";
import Filestore from "../images/filestore.png";

const ProjectCardsContent = [
  {
    key: "whiteboard",
    title: "Whiteboard",
    description:
      "A learning management system built to provide online learning experience to student in South Asia.",
    img: "",
    path: "/whiteboard",
  },
  {
    key: "truckbux",
    title: "TruckBux",
    description:
      "An iOS and Android mobile application for food truck ordering.",
    img: TruckBux,
    path: "/truckbux",
  },
  {
    key: "coalitionspace",
    title: "Coalition Space",
    description: "An office space renting web application.",
    img: Coalition,
    path: "/coalition",
  },
  {
    key: "filestore",
    title: "Filestore",
    description:
      "A wrapper library to support file transfer using Amazon S3 and Oracle Cloud Infrastructure.",
    img: Filestore,
    path: "/filestore",
  },
  {
    key: "personalweb",
    title: "Personal Website",
    description:
      "Personal website built with ReactJS, MaterialUI and React-Spring.",
    img: "",
    path: "/personalweb",
  },
  {
    key: "enigma",
    title: "Enigma",
    description:
      "A Java application to simulate the operation of the enigma machine, an encrypt and decrypt message device during WWII.",
    img: Enigma,
    path: "/enigma",
  },
];

export { ProjectCardsContent };
