import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    height: "250px",
    width: "290px",
  },
  [theme.breakpoints.only("sm")]: {
    height: "180px",
    width: "280px",
  },
  [theme.breakpoints.only("md")]: {
    height: "180px",
    width: "320px",
  },
  [theme.breakpoints.only("lg")]: {
    height: "180px",
    width: "380px",
  },
  [theme.breakpoints.up("xl")]: {
    height: "250px",
    width: "500px",
  },
  margin: "0 auto",
  background: theme.palette.secondary.main,
  "&:hover": {
    background: theme.palette.secondary.light,
  },
}));

const MyCardActionArea = styled(CardActionArea)(({ theme }) => ({
  width: "100%",
  height: "100%",
  "&:hover": {
    background: theme.palette.secondary.light,
  },
}));

const MyCardMedia = styled(CardMedia)(({ theme }) => ({
  height: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const MyCardContent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.fontSizeCustom.body1,
    letterSpacing: theme.typography.letterSpacing.body1,
  },
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: theme.typography.fontSizeCustom.body1,
    letterSpacing: theme.typography.letterSpacing.body1,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: theme.typography.fontSizeCustom.h6,
    letterSpacing: theme.typography.letterSpacing.h6,
  },
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamilyName,
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  padding: theme.spacing(2),
  textAlign: "left",
  height: "50%",
}));

const MyCardTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.fontSizeCustom.h4,
    letterSpacing: theme.typography.letterSpacing.h4,
  },
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: theme.typography.fontSizeCustom.h4,
    letterSpacing: theme.typography.letterSpacing.h4,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: theme.typography.fontSizeCustom.h3,
    letterSpacing: theme.typography.letterSpacing.h3,
  },
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamilyName,
  color: theme.palette.common.white,
  textAlign: "center",
  padding: theme.spacing(1),
}));

export { MyCard, MyCardActionArea, MyCardMedia, MyCardContent, MyCardTitle };
