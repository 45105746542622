import WbDemo1 from "../imagesdemo/wbdemo1.png";
import WbDemo2 from "../imagesdemo/wbdemo2.png";
import WbDemo3 from "../imagesdemo/wbdemo3.png";
import TbDemo1 from "../imagesdemo/tbdemo1.png";
import TbDemo2 from "../imagesdemo/tbdemo2.png";
import TbDemo3 from "../imagesdemo/tbdemo3.png";
import TbDemo4 from "../imagesdemo/tbdemo4.png";
import FsDemo1 from "../imagesdemo/fsdemo1.png";
import FsDemo2 from "../imagesdemo/fsdemo2.png";
import PwDemo1 from "../imagesdemo/pwdemo1.png";
import PwDemo2 from "../imagesdemo/pwdemo2.png";
import EgDemo1 from "../imagesdemo/egdemo1.png";
import EgDemo2 from "../imagesdemo/egdemo2.png";
import CsDemo1 from "../imagesdemo/csdemo1.jpg";
import CsDemo2 from "../imagesdemo/csdemo2.png";

const ProjectDetailsContent = {
  whiteboard: {
    title: "Whiteboard",
    summary:
      "A collaboration work with Dhruva Juloori to build a learning management system. The application main goal is to give students and educators in South Asia an affordable online platform for homework, documentations, exams and keep track of their school performances. We are working toward the development of critical features such as user interface, exams, file storage and events calendar along with automated testing. Our plan to launch the product on September 1, 2020.",
    tech: "Java SpringBoots, Microservices, Docker, ReactJS, Material-UI",
    link: "Comming Soon",
    img: [
      {
        key: "wbdemo1",
        image: WbDemo1,
      },
      {
        key: "wbdemo2",
        image: WbDemo2,
      },
      { key: "wbdemo3", image: WbDemo3 },
    ],
  },
  truckbux: {
    title: "TruckBux",
    summary:
      "A startup for food truck mobile ordering I worked for over the summer of 2019. As the company planed to expand their business from pickup to delivery, I worked with Nick Nanakos, the CEO of TruckBux to focused on implementing an API from Habitat, a statistic company, to use their drivers for shipment, created database table to store information for delivery order and refactor Android app user interface.",
    tech: "Java SpringBoots, Android Studio, MySQLWorkbench",
    link: "https://truckbux.com/",
    img: [
      {
        key: "tbdemo1",
        image: TbDemo1,
      },
      {
        key: "tbdemo2",
        image: TbDemo2,
      },
      { key: "tbdemo3", image: TbDemo3 },
      { key: "tbdemo4", image: TbDemo4 },
    ],
  },
  coalition: {
    title: "Coalition Space",
    summary:
      "My senior design project to improve an office renting application. Our team has 6 members including me: Saffat Hasan, Deb Stoddard, Sam Truyen, Alisha Fnu, Rishi Singh. The main goal is to make Coalition Space internal web application available for public use. As the current system is not user friendly with complicated renting process that only employee knows how to use, we have successfully redesigned the user interface to make it more appealing and straight forward. We also upgraded the developer onboarding process by creating the deployable container for development environment using SaltStack.",
    tech: "Python Flask, HTML/CSS, Bootstrap, SaltStack",
    link:
      "http://www.cci.drexel.edu/SeniorDesign/2019_2020/Coalition/Coalition-index.html",
    img: [
      {
        key: "csdemo1",
        image: CsDemo1,
      },
      {
        key: "csdemo2",
        image: CsDemo2,
      },
    ],
  },
  filestore: {
    title: "Filestore",
    summary:
      "A wrapper library I built to abstract the robust process of making requests for object store using AWS S3 and Oracle Cloud SDK. This project is fully tested and can be included in a maven application.",
    tech: "Maven, Java, AWS SDK, OCI SDK",
    link: "https://gitlab.com/duketran1996/filestore",
    img: [
      {
        key: "fsdemo1",
        image: FsDemo1,
      },
      {
        key: "fsdemo2",
        image: FsDemo2,
      },
    ],
  },
  personalweb: {
    title: "Personal Website",
    summary:
      "My first experience with ReactJs and React-Spring to create complex animations as well as static data store using Firebase. The theme was inspired by my experience with position and negative space along with neon sign highlight I learned in my photography class.",
    tech: "ReactJS, React-Spring, Filebase, Google Cloud",
    link: "https://minhthu.info/",
    img: [
      {
        key: "pwdemo1",
        image: PwDemo1,
      },
      {
        key: "pwdemo2",
        image: PwDemo2,
      },
    ],
  },
  enigma: {
    title: "Enigma Machine",
    summary:
      "A Java application to simulate the operation of the Enigma Machine use in WWII for messages encryption and decryption.",
    tech: "Java",
    link: "https://gitlab.com/duketran1996/enigma_machine",
    img: [
      {
        key: "egdemo1",
        image: EgDemo1,
      },
      {
        key: "egdemo2",
        image: EgDemo2,
      },
    ],
  },
};

export { ProjectDetailsContent };
