import { AppBar, Tabs, Tab } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyAppBar = styled(AppBar)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const MyTabs = Tabs;

const MyTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.fontSizeCustom.body1,
    letterSpacing: theme.typography.letterSpacing.body1,
  },
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: theme.typography.fontSizeCustom.body1,
    letterSpacing: theme.typography.letterSpacing.body1,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: theme.typography.fontSizeCustom.h6,
    letterSpacing: theme.typography.letterSpacing.h6,
  },
  borderRadius: theme.shape.borderRadiusRound,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.dark,
  "&:hover": {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export { MyAppBar, MyTabs, MyTab };
