import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5),
  height: "100%",
  width: "100%",
}));

const MyHeaderContent = Grid;

const MyBodyContent = Grid;

const MyFooterContent = Grid;

export { MyContainer, MyHeaderContent, MyBodyContent, MyFooterContent };
