import { createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import blueGrey from "@material-ui/core/colors/blueGrey";

const CustomLightTheme = createMuiTheme({
  palette: {
    primary: {
      verylight: blueGrey[400],
      light: blueGrey[500],
      main: blueGrey[600],
      dark: blueGrey[700],
      constrastText: blueGrey[50],
    },
    secondary: {
      verylight: grey[400],
      light: grey[500],
      main: grey[600],
      dark: grey[700],
      constrastText: blueGrey[50],
    },
    icon: {
      blue: "#0E76A8",
    },
    success: {
      main: green[600],
    },
  },
  shape: {
    borderRadiusRound: 10,
    borderRadius: 6,
    borderRadiusSquare: 0,
    borderRadiusDefault: 4,
  },
  typography: {
    fontFamilyMarker: '"Permanent Marker", sans-serif',
    fontFamilyDefault: '"Roboto","Helvetica", "Arial", sans-serif',
    fontFamilyName: '"brandon-grotesque", sans-serif',
    fontFamilyTitle: '"Lato", sans-serif',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 14,
    fontSizeCustom: {
      h1: "6rem",
      h2: "3.75rem",
      h3: "3rem",
      h4: "2.125rem",
      h5: "1.5rem",
      h6: "1.25rem",
      iconsm: "2.25rem",
      iconlg: "2.45rem",
      subtitle1: "1rem",
      subtitle2: "0.875rem",
      body1: "1rem",
      body2: "0.875rem",
      button: "0.875rem",
      caption: "0.75rem",
      overline: "0.75rem",
    },
    letterSpacing: {
      h1: "-0.01562em",
      h2: "-0.00833em",
      h3: "0em",
      h4: "0.00735em",
      h5: "0em",
      h6: "0.0075em",
      subtitle1: "0.00938em",
      subtitle2: "0.00714em",
      body1: "0.00938em",
      body2: "0.01071em",
      button: "0.02857em",
      caption: "0.03333em",
      overline: "0.08333em",
    },
    lineHeight: {
      h1: 1.167,
      h2: 1.2,
      h3: 1.167,
      h4: 1.235,
      h5: 1.334,
      h6: 1.6,
      subtitle1: 1.75,
      subtitle2: 1.57,
      body1: 1.5,
      body2: 1.43,
      button: 1.75,
      caption: 1.66,
      overline: 2.66,
    },
  },
});

export default CustomLightTheme;
