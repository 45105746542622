import React, { useState, Fragment } from "react";
import { CssBaseline } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as Style from "./CardProjectStyles";

export default function CardProject(props) {
  const { info } = props;
  const [isShown, setIsShown] = useState(false);
  const history = useHistory();

  return (
    <Fragment>
      <CssBaseline />
      <Style.MyCard>
        <Style.MyCardActionArea
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          onClick={() => history.push(info["path"])}
        >
          <Style.MyCardMedia image={isShown ? info["img"] : ""}>
            {isShown ? (
              info["img"] === "" ? (
                <Style.MyCardTitle>{info["title"]}</Style.MyCardTitle>
              ) : null
            ) : (
              <Style.MyCardTitle>{info["title"]}</Style.MyCardTitle>
            )}
          </Style.MyCardMedia>
          {isShown ? (
            <Style.MyCardContent>{info["description"]}</Style.MyCardContent>
          ) : null}
        </Style.MyCardActionArea>
      </Style.MyCard>
    </Fragment>
  );
}
