import React, { Fragment } from "react";
import { CssBaseline } from "@material-ui/core";
import TopBar from "../NavigationBar/TopBar";
import Name from "../Header/Name";
import Title from "../Header/Title";
import ConnectWithMe from "../Footer/ConnectWithMe";
import * as Style from "./MainLayoutGridStyles";

export default function MainLayoutGrid(props) {
  return (
    <Fragment>
      <CssBaseline />
      <Style.MyContainer
        container
        direction="column"
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
      >
        <Style.MyHeaderContent key={"header"} item>
          <Name />
          <Title />
          <TopBar />
        </Style.MyHeaderContent>
        <Style.MyBodyContent key={"body"} item>
          {props.children}
        </Style.MyBodyContent>
        <Style.MyFooterContent key={"footer"} item>
          <ConnectWithMe />
        </Style.MyFooterContent>
      </Style.MyContainer>
    </Fragment>
  );
}
