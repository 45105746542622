import React, { Fragment } from "react";
import { Grid, CssBaseline, ThemeProvider } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import CustomLightTheme from "../../themes/CustomLightTheme";
import CardProject from "./sub-components/CardProject";
import Grow from "@material-ui/core/Grow";
import * as Data from "../../contents/ProjectCardsContent";
import * as Style from "./ProjectStyles";

function Project(props) {
  const projectInfo = Data.ProjectCardsContent;

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Grow in={true}>
          <Style.MyGridContainer
            container
            spacing={4}
            direction="row"
            alignItems="center"
            alignContent="center"
            justify="space-evenly"
          >
            {projectInfo.map((info) => (
              <Grid key={info["key"]} item sm={5} md={5} lg={4}>
                <CardProject info={info} />
              </Grid>
            ))}
          </Style.MyGridContainer>
        </Grow>
        {isWidthDown("sm", props.width) ? <Style.MyKeyboardArrowRight /> : null}
      </ThemeProvider>
    </Fragment>
  );
}

export default withWidth()(Project);
