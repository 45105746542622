import React, { Fragment } from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CustomLightTheme from "../../themes/CustomLightTheme";
import * as Style from "./NameStyles";

export default function Name() {
  const history = useHistory();
  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={CustomLightTheme}>
        <Style.MyName align='center' onClick={() => history.push('/')}>Duc Tran</Style.MyName>
      </ThemeProvider>
    </Fragment>
  );
}