import {
  Modal,
  CardActionArea,
  Card,
  Grid,
  CardMedia,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyGridContainer = styled(Grid)(({ theme }) => ({}));

const MyCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    height: "250px",
    width: "290px",
  },
  [theme.breakpoints.only("sm")]: {
    height: "180px",
    width: "280px",
  },
  [theme.breakpoints.only("md")]: {
    height: "180px",
    width: "320px",
  },
  [theme.breakpoints.only("lg")]: {
    height: "200px",
    width: "300px",
  },
  [theme.breakpoints.up("xl")]: {
    height: "250px",
    width: "400px",
  },
}));

const MyCardActionArea = styled(CardActionArea)(({ theme }) => ({
  width: "100%",
  height: "100%",
  "&:hover": {
    background: theme.palette.secondary.light,
  },
}));

const MyCardMedia = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
}));

const MyModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const MyCardModal = styled(Card)(({ theme }) => ({
  height: "500px",
  width: "1000px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.secondary.dark,
  borderRadius: theme.shape.borderRadiusRound,
  outline: "none",
}));

const MyCardMediaModal = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: "100%",
}));

export {
  MyGridContainer,
  MyCard,
  MyCardActionArea,
  MyCardMedia,
  MyModal,
  MyCardModal,
  MyCardMediaModal,
};
