import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyProjectSection = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.fontSizeCustom.h6,
    letterSpacing: theme.typography.letterSpacing.h6,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.fontSizeCustom.h5,
    letterSpacing: theme.typography.letterSpacing.h5,
  },
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.dark,
}));

export { MyProjectSection };
