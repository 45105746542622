import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.fontSizeCustom.h5,
    letterSpacing: theme.typography.letterSpacing.h5,
  },
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: theme.typography.fontSizeCustom.h5,
    letterSpacing: theme.typography.letterSpacing.h5,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: theme.typography.fontSizeCustom.h4,
    letterSpacing: theme.typography.letterSpacing.h4,
  },
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.dark,
}));

export { MyTitle };
