import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//import "./App.css";
import MainScene from "./scenes/MainScene";

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/:path?" exact>
            <MainScene />
          </Route>
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
