import React, { Fragment } from "react";
import { CssBaseline } from "@material-ui/core";
import * as Style from "./ProjectSectionStyles";

export default function ProjectSection(props) {
  return (
    <Fragment>
      <CssBaseline />
      <Style.MyProjectSection>{props.data}</Style.MyProjectSection>
    </Fragment>
  );
}